import React from "react";

import Navbar from "../components/Navbar.tsx";

const Landing = () => {
  return (
    <div>
      <Navbar />
      <div className="container">
        <img
          src={require("../assets/ocean_background.jpeg")}
          alt="background"
          className="background"
        />
        <div className="me-info">
          <img
            src={require("../assets/gus_flusser.png")}
            alt="gus flusser"
          ></img>
          <div className="right-info">
            <h1>Gus Flusser</h1>
            <div className="buttons">
              <a href="/#/about" className="button">
                About Me
              </a>
              <a
                href={require("../assets/Gus_Flusser.pdf")}
                className="button"
                download
              >
                My Resume
              </a>
              <a href="/#/projects" className="button">
                Projects
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
