import React, { useState } from "react";

const HoverButton = ({ text, link, description }) => {
  const [hovered, setHovered] = useState(false);

  const onHoverOn = () => {
    setHovered(true);
  };

  const onHoverOff = () => {
    setHovered(false);
  };

  return (
    <div style={styles.container}>
      <a
        href={link}
        className="button"
        target="_blank"
        rel="noopener noreferrer"
        onMouseEnter={onHoverOn}
        onMouseLeave={onHoverOff}
      >
        {text}
      </a>
      {hovered && (
        <div style={{ ...styles.description, top: "40px" }}>
          <p>{description}</p>
        </div>
      )}
    </div>
  );
};

const styles: any = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative", // Ensure the container is relatively positioned
  },
  description: {
    position: "absolute",
    borderRadius: "5px",
    fontSize: "14px",
    backgroundColor: "#ffffffaa",
    paddingLeft: "5px",
    paddingRight: "5px",
    textAlign: "center",
  },
};

export default HoverButton;
