import React from "react";
import Navbar from "../components/Navbar.tsx";

const Projects = () => {
  const projects = [
    {
      name: "Flick",
      logo: require("../assets/logos/flick.png"),
      description:
        "Flick is the second of two movie searching and tracking applications I created. It allows users to search, save, rate, share, discover, and review movies and TV shows as well as their cast and crew.",
      //   link: "",
      image: require("../assets/project_images/flick.png"),
    },
    {
      name: "Route",
      logo: require("../assets/logos/route.png"),
      description:
        "Route is a mobile application I designed, developed, released, and am currently maintaining for Cal Poly students. I programmed it from the ground up during fall quarter 2023. It enables students to carpool short and long distances, and currently has 500+ registered users.",
      link: "https://apps.apple.com/us/app/route-get-around/id6468181288",
      image: require("../assets/project_images/route.png"),
    },
    {
      name: "Oasis",
      logo: require("../assets/logos/oasis.png"),
      description:
        "Oasis is another mobile application I designed and programmed from scratch. This was a social application designed as an alternative to the school Snapchat stories.",
      //   link: "",
      image: require("../assets/project_images/oasis.png"),
    },
    {
      name: "MovieMemoir",
      logo: require("../assets/logos/moviememoir.png"),
      description:
        "MovieMemoir is the first of two movie searching and tracking applications I have created. It enables users to discover and rate movies and TV shows, and save them to watchlists.",
      link: "https://apps.apple.com/us/app/movie-memoir/id6448244072",
      image: require("../assets/project_images/moviememoir.png"),
    },
    {
      name: "Christmas Time",
      logo: require("../assets/logos/christmastime.png"),
      description:
        "Christmas Time is the project that piqued my interest in software engineering and mobile app development. It is a simple app that displays the time left until Christmas in a variety of ways.",
      link: "https://apps.apple.com/us/app/christmas-time-countdown/id6443821135",
      image: require("../assets/project_images/christmastime.png"),
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="container">
        <img
          src={require("../assets/ocean_background.jpeg")}
          alt="background"
          className="background"
        />

        <div className="project-grid">
          {projects.map((project, index) => (
            <div key={index} className="project-card">
              <img src={project.logo} alt={project.name} className="logo" />
              <h2>{project.name}</h2>
              <p>{project.description}</p>
              <img
                src={project.image}
                alt={project.name}
                className="project-image"
              />
              <a
                href={project.link ? project.link : ""}
                target="_blank"
                rel="noopener noreferrer"
                className="button"
              >
                {project.link ? "See More" : "Not Currently Available"}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
