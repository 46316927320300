import React from "react";

const Navbar = () => {
  return (
    <nav>
      <div className="nav-container">
        <ul>
          <li>
            <a href="/" className="nav-home">
              <img
                src={require("../assets/gus_flusser.png")}
                alt="gus flusser"
                className="nav-logo"
              ></img>
            </a>
          </li>
          <li>
            <a href="/#/about" className="nav-link">
              About
            </a>
          </li>
          <li>
            <a href="/#/projects" className="nav-link">
              Projects
            </a>
          </li>
        </ul>
        <ul>
          <li className="no-mobile">
            <a
              href="
                https://www.linkedin.com/in/gusflus/"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              LinkedIn
            </a>
          </li>
          <li className="no-mobile">
            <a
              href="https://github.com/gusflus"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              Github
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
