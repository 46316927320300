import React from "react";
import HoverButton from "../components/HoverButton.tsx";
import Navbar from "../components/Navbar.tsx";

const About = () => {
  return (
    <div>
      <Navbar />
      <div className="about">
        <div className="container">
          <img
            src={require("../assets/ocean_background.jpeg")}
            alt="background"
            className="background"
          />

          <div className="about-info">
            <div className="section">
              <img
                src={require("../assets/gus_ramsey.jpeg")}
                alt="gus flusser"
              />
              <div className="text-content">
                <h2>About Me</h2>
                <p>
                  Hey! I'm Gus. I am a second-year Computer Engineering student
                  at Cal Poly. I spend almost all of my free time working on
                  personal and entrepreneurial projects, which I have linked
                  below.
                </p>
                <p>
                  Apart from school and programming, I love to listen to music,
                  watch movies, play chess, go running, and spending time with
                  friends and family.
                </p>
                <div className="links">
                  <div className="link-buttons">
                    <a
                      href={require("../assets/Gus_Flusser.pdf")}
                      download
                      className="button"
                    >
                      My Resume
                    </a>
                    <a href="/#/projects" className="button">
                      Projects
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="socials">
              <h3>Check me out here too:</h3>
              <div className="social-buttons">
                <HoverButton
                  text="GitHub"
                  link="https://github.com/gusflus"
                  description="My GitHub"
                />
                <HoverButton
                  text="LinkedIn"
                  link="https://www.linkedin.com/in/gusflus"
                  description="My LinkedIn"
                />
                <HoverButton
                  text="Email"
                  link="mailto:gus.flusser@gmail.com"
                  description="gus.flusser@gmail.com"
                />
                <HoverButton
                  text="Phone"
                  link="tel:424-400-1314"
                  description="(424) 400-1314"
                />
                <HoverButton
                  text="App Store"
                  link="https://apps.apple.com/us/developer/gus-flusser/id1649666344"
                  description="See my Apps!"
                />
                <HoverButton
                  text="YouTube"
                  link="https://www.youtube.com/@goldmiser"
                  description="Some Minecraft videos"
                />
                <HoverButton
                  text="Chess.com"
                  link="https://www.chess.com/stats/overview/gusflus/7?"
                  description="My chess account"
                />
                <HoverButton
                  text="Instagram"
                  link="https://www.instagram.com/gusflus/"
                  description="For pictures of me"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
